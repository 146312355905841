<template>
  <div class="friday">
    <div class="header">
      <!-- <img class="top_title" src="@/assets/eleven/tittle_en.png" @click="handleBack" alt=""> -->
      <img src="@/assets/eleven/Slice7en-min.png" alt=""  @click="handleBack">
    </div>
    <!-- <img  src="@/assets/eleven/blacktittle.png" @click="handleBack" alt=""> -->
    <img src="@/assets/eleven/Slice8en-min.png" alt="">
    <img src="@/assets/eleven/Slice9en-min.png" alt="">
    <img src="@/assets/eleven/Slice10en-min.png" alt="">
    <img src="@/assets/eleven/Slice11en-min.png" alt="">
  </div>
</template>

<script>
 export default {
    data () {
      return {

      }
    },
    methods: {
      handleBack(){
        if(this.$route.query.back) {
          this.$router.push(`/en/${this.$route.query.back}`)
        } else {
          let path = localStorage.getItem('_back')
          if(path) {
            localStorage.removeItem('_back')
            this.$router.push(path)
          } else {
            this.$router.go(-1)
          }
        }
      } 
    }
 }
</script>

<style lang="less" scoped>
.friday {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  background: #1C1D24;
  .header {
    position: relative;
    .top_title {
      position: absolute;
      top: 0;
    }
  }
    img {
        display: block;
        width: 100%;
    }
}
</style>
